import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import SlideVerify from 'vue-monoplasty-slide-verify';
import md5 from 'js-md5';

import preventReClick from './utils/plugins.js'



import tool from "./utils/session";
Vue.use(tool);


Vue.use(preventReClick);
Vue.use(SlideVerify);
Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.prototype.$md5 = md5;
Vue.filter('capitalize', function (value) {
  if (!value) return '0'
  return '￥'+value
})
import defaultImages from "@/assets/defaultImage.png";
Vue.filter('defaultImage', function (value) {
  var defaultImage = defaultImages;
  if(!value){
    return defaultImage
  }else{
    return value
  } 
})
import VueWechatTitle from 'vue-wechat-title' //动态修改title
Vue.use(VueWechatTitle);
// 根据路由设置标题
router.beforeEach((to, from, next) => {
  try {  
    // 路由守卫逻辑  
    /*路由发生改变修改页面的title */
    if(to.meta.title) {
      if(to.meta.title=="首页"){
        document.title ="3A医药城 - 复盛公药业"
      }else if(to.meta.title=="登录"){
        document.title ="3A医药城 - 复盛公药业"
      }else{
        document.title = to.meta.title+'-3A医药城'
      }
    }
    next();
  } catch (error) {  
    console.error('Route guard error:', error);  
    next(false); // 或者重定向到错误页面  
  }  
  
})

Vue.filter('defaultName', function (value) {
  if(!value){
    return " "
  }else{
    return value
  }
})

// 埋点
import eventLog from './utils/eventLog.js'
Vue.use(eventLog)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
